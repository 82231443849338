var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrap", attrs: { id: "wrap" } },
    [
      _c(
        "cardTitleCom",
        { staticClass: "cardTitle", attrs: { cardTitle: "单据信息" } },
        [
          _c("template", { slot: "cardContent" }, [
            _c(
              "div",
              { staticClass: "tableContent" },
              [
                _c("operatingButton", {
                  attrs: {
                    getList: _vm.getList,
                    isRecycleBtn: true,
                    isDeleteBtn: false,
                    multiple: _vm.multiple,
                  },
                  on: {
                    handleAdd: _vm.handleAdd,
                    handleDelete: _vm.handleDelete,
                    handleRedoDelete: function ($event) {
                      _vm.dialogOptions.show = true
                    },
                  },
                }),
                _c(
                  "el-table",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingTable,
                        expression: "loadingTable",
                      },
                    ],
                    ref: "multipleTable",
                    attrs: {
                      "tooltip-effect": "dark",
                      data: _vm.tableData,
                      border: "",
                      "max-height": _vm.tableHeight,
                      height: _vm.tableHeight,
                    },
                    on: {
                      "row-click": _vm.handleRowClick,
                      "selection-change": _vm.handleSelectionChange,
                    },
                  },
                  [
                    _c("el-table-column", {
                      attrs: {
                        type: "selection",
                        width: "50",
                        selectable: _vm.disableCheckbox,
                        align: "center",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "序号",
                        align: "center",
                        prop: "userId",
                        width: "80",
                        type: "index",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "方案编号",
                        align: "center",
                        prop: "priceCaseNo",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-link",
                                {
                                  attrs: {
                                    disabled: scope.row.specialFlag == "*",
                                    type: "primary",
                                    target: "_blank",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleDetail(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(scope.row.priceCaseNo) + " "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "方案名称",
                        align: "center",
                        prop: "priceCaseName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        "show-overflow-tooltip": "",
                        label: "价格因子",
                        align: "center",
                        prop: "priceTypeName",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "计算方式",
                        align: "center",
                        prop: "priceCalcType",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dict-tag", {
                                attrs: {
                                  options: _vm.dict.type.goods_price_calc_type,
                                  value: scope.row.priceCalcType,
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "计算值",
                        align: "center",
                        prop: "priceValue",
                      },
                    }),
                    _c("el-table-column", {
                      attrs: {
                        label: "舍入方式",
                        align: "center",
                        prop: "roundMode",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("dict-tag", {
                                attrs: {
                                  options: _vm.dict.type.goods_round_mode,
                                  value: scope.row.roundMode,
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
                _c("bottomPage", {
                  attrs: {
                    getList: _vm.getList,
                    idsCom: _vm.ids,
                    totalCom: _vm.total,
                  },
                  on: { selectAllCom: _vm.selectAll },
                  model: {
                    value: _vm.queryParams,
                    callback: function ($$v) {
                      _vm.queryParams = $$v
                    },
                    expression: "queryParams",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("c-dialog", {
        attrs: { title: _vm.title, width: 730, showDialog: _vm.dialogVisible },
        on: {
          "update:showDialog": function ($event) {
            _vm.dialogVisible = $event
          },
          "update:show-dialog": function ($event) {
            _vm.dialogVisible = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "el-form",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.loadingDialog,
                        expression: "loadingDialog",
                      },
                    ],
                    ref: "form",
                    attrs: {
                      model: _vm.priceCaseForm,
                      "label-width": "90px",
                      rules: _vm.rules,
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "方案编号", prop: "priceCaseNo" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidthM",
                              attrs: {
                                autocomplete: "off",
                                placeholder: "请输入方案编号",
                                maxlength: "4",
                              },
                              model: {
                                value: _vm.priceCaseForm.priceCaseNo,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.priceCaseForm,
                                    "priceCaseNo",
                                    $$v
                                  )
                                },
                                expression: "priceCaseForm.priceCaseNo",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "方案名称", prop: "priceCaseName" },
                          },
                          [
                            _c("el-input", {
                              staticClass: "inputWidthM",
                              attrs: {
                                autocomplete: "off",
                                placeholder: "请输入方案名称",
                              },
                              model: {
                                value: _vm.priceCaseForm.priceCaseName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.priceCaseForm,
                                    "priceCaseName",
                                    $$v
                                  )
                                },
                                expression: "priceCaseForm.priceCaseName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-x" },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "价格因子", prop: "priceType" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidthM",
                                attrs: {
                                  placeholder: "请选择价格因子",
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.priceCaseForm.priceType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.priceCaseForm,
                                      "priceType",
                                      $$v
                                    )
                                  },
                                  expression: "priceCaseForm.priceType",
                                },
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: "标准售价", value: 1 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "标准进价", value: 2 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "最新进价", value: 3 },
                                }),
                                _c("el-option", {
                                  attrs: { label: "最新成本价", value: 4 },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "舍入方式", prop: "roundMode" } },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidthM",
                                attrs: {
                                  placeholder: "请选择舍入方式",
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.priceCaseForm.roundMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.priceCaseForm,
                                      "roundMode",
                                      $$v
                                    )
                                  },
                                  expression: "priceCaseForm.roundMode",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.goods_round_mode,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "x-f" },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: { label: "计算方式", prop: "priceCalcType" },
                          },
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "inputWidthM",
                                attrs: {
                                  placeholder: "请选择计算方式",
                                  clearable: "",
                                  filterable: "",
                                },
                                model: {
                                  value: _vm.priceCaseForm.priceCalcType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.priceCaseForm,
                                      "priceCalcType",
                                      $$v
                                    )
                                  },
                                  expression: "priceCaseForm.priceCalcType",
                                },
                              },
                              _vm._l(
                                _vm.dict.type.goods_price_calc_type,
                                function (dict) {
                                  return _c("el-option", {
                                    key: dict.value,
                                    attrs: {
                                      label: dict.label,
                                      value: dict.value,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: "计算值", prop: "priceValue" } },
                          [
                            _c("el-input", {
                              staticClass: "inputWidthM",
                              attrs: {
                                type: "number",
                                autocomplete: "off",
                                placeholder: "请输入计算值",
                              },
                              model: {
                                value: _vm.priceCaseForm.priceValue,
                                callback: function ($$v) {
                                  _vm.$set(_vm.priceCaseForm, "priceValue", $$v)
                                },
                                expression: "priceCaseForm.priceValue",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "buttonList",
            fn: function () {
              return [
                _c("el-button", { on: { click: _vm.cancel } }, [
                  _vm._v("取 消"),
                ]),
                _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.submitForm } },
                  [_vm._v("确 定")]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("Dialog2", {
        attrs: { options: _vm.dialogOptions },
        on: {
          "update:options": function ($event) {
            _vm.dialogOptions = $event
          },
          handleEvent: _vm.getList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }