<template>
  <div class="wrap" id="wrap">
    <!-- 关系情况 -->
    <div class="content" v-loading="loading">
      <cardTitleCom cardTitle="商品捆绑/混售" class="cardDetail">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table tooltip-effect="dark" :data="tableDataDetail" border :max-height="tableHeight"
              :height="tableHeight" v-loading="loadingDetail" @row-click="handleRowClick">
              <el-table-column width="50" align="center">
                <template v-slot="scope">
                  <el-radio v-model="radioItem" :label="scope.row">{{
                    ''
                  }}</el-radio>
                </template>
              </el-table-column>
              <el-table-column label="序号" align="center" prop="userId" width="60" type="index" />
              <el-table-column label="商品类型" align="center" prop="goodsTypeName" min-width="80" />
              <el-table-column label="商品编码" align="center" prop="goodsNo" min-width="120" />
              <el-table-column label="商品名称" align="center" prop="goodsName" min-width="120" />
              <el-table-column label="规格" align="center" prop="goodsSpec" min-width="60" />
              <el-table-column label="单位" align="center" prop="unitName" min-width="60" />
              <el-table-column label="进价" align="center" prop="purPrice" min-width="80" />
              <el-table-column label="售价" align="center" prop="salePrice" min-width="80" />
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :isSelectAll="false" :leftTotalCom="false"
              :totalCom="total" />
          </div>
        </template>
      </cardTitleCom>
      <el-card class="cardAvailable">
        <div class="cardTitle x-bc">
          <span class="titleTop marR10">{{ selectGoodsName }}</span>
          <el-button class="marR10" type="primary" size="mini" @click="submitForm">保存
          </el-button>
        </div>

        <div class="tableContent">
          <el-table tooltip-effect="dark" :data="tableDataAvailable" border :max-height="tableHeight"
            :height="tableHeight" v-loading="loadingAvailable" @cell-mouse-enter="cellMouseEnter"
            @cell-mouse-leave="cellMouseLeave">
            <el-table-column align="center" :label="tableCellLabel" width="80">
              <template v-slot="scope">
                <i v-if="scope.row.hoverRow" @click="rowAvailable('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"></i>
                <i v-if="scope.row.hoverRow" @click="rowAvailable('del', scope.$index)"
                  class="el-icon-remove operateDel"></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>
            <el-table-column label="商品编码" min-width="150" align="center" prop="goodsNo">
              <template slot="header">
                <i style="color: #ff4949">* </i>
                <span>商品编码</span>
              </template>
              <template slot-scope="scope">
                <SelectRemote size="mini" v-model="scope.row.goodsNo" :option="optionGoods"
                  :ref="'goodsNo'+'_'+scope.$index +'_1'" :filterable="true" placeholder="选择商品" />
              </template>
            </el-table-column>
            <el-table-column label="商品名称" align="center" prop="goodsName" min-width="120" />
            <el-table-column label="规格" align="center" prop="goodsSpec" min-width="60" />
            <el-table-column label="单位" align="center" prop="unitId" min-width="100">
              <template slot="header">
                <i style="color: #ff4949">* </i>
                <span>单位</span>
              </template>
              <template slot-scope="scope">
                <el-select size="mini" @change="unitPriceChange($event, scope.row)" v-model="scope.row.unitId">
                  <el-option v-for="item in scope.row.units" :key="item.unitId" :label="item.unitName"
                    :value="item.unitId" />
                </el-select>
              </template>
            </el-table-column>
            <el-table-column label="进价" align="center" prop="purPrice" min-width="80" />
            <el-table-column label="售价" align="center" prop="salePrice" min-width="80" />
            <el-table-column label="成分数量" align="center" prop="goodsQty" min-width="100"
              v-if="radioItem && (radioItem.goodsType == 7)" key="goodsQty">
              <template slot="header">
                <i style="color: #ff4949">* </i>
                <span>成分数量</span>
              </template>
              <template slot-scope="scope">
                <el-input @keyup.native="goodsQtyChange(scope.row)"
                  @input="limitInputlength(scope.row.goodsQty, scope.row, 'goodsQty', true, 4)" size="mini"
                  v-model="scope.row.goodsQty" maxlength="8" />
              </template>
            </el-table-column>
            <el-table-column label="生成率(%)" align="center" prop="goodsRate" min-width="100"
              v-if="radioItem && (radioItem.goodsType == 6)" key="goodsRate">
              <template slot="header">
                <i style="color: #ff4949">* </i>
                <span>生成率(%)</span>
              </template>
              <template slot-scope="scope">
                <el-input @input="limitInputlength(scope.row.goodsRate, scope.row, 'goodsRate', true, 4)" type="number"
                  size="mini" v-model="scope.row.goodsRate" maxlength="8" />
              </template>
            </el-table-column>
            <el-table-column label="进价金额" align="center" prop="purMoney" min-width="80" />
            <el-table-column label="售价金额" align="center" prop="saleMoney" min-width="80" />
          </el-table>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import bottomPage from "@/views/components/bottomPage/index.vue"; //底部分页
import { limitInputlength, fcount, deepCopy } from '@/utils'
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { listGoods } from "@/api/goods/goods";
import {
  saveGoodsComboDetail,
  getGoodsCombo
} from '@/api/system/goods/bindOrMixedSale' //分店价格方案
export default {
  name: 'bindOrMixedSale',
  components: { cardTitleCom, SelectRemote, bottomPage },
  data() {
    return {
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 15,
        goodsTypes: [6, 7]
      },
      optionGoods: this.$select({
        key: "listGoods",
        option: {
          option: {
            noEcho: true,
            label: "goodsNo",
            change: (propValue, row) => {
              if (propValue) {
                const findIndex = this.tableDataAvailable.findIndex(
                  (item) => item.goodsNo === propValue
                );
                let rows = deepCopy(row);
                rows.findIndex = findIndex;
                this.getGoodS([rows]);
              }
            },
            tableChange: (propValue, row) => {
              this.getGoodS(row.check);
            },
            buttons: [
              {
                type: "more",
                option: {
                  title: "选择商品",
                  width: 1250,
                  type: "TreeAndTable",
                  formData: this.$dialog({
                    key: "goods",
                    option: {
                      table: {
                        ...this.$dialog({ key: "goods" }).table,
                      },
                    },
                  }),
                },
              },
            ],
          },
        },
      }).option,
      tableCellLabel: '序号',
      radioItem: undefined, //单选
      goodsId: undefined, //焦点方案id
      selectGoodsName: '捆绑商品', //当前选中的方案信息的名称
      loading: false, //遮罩层
      loadingDetail: false, //价格方案表格的加载
      loadingAvailable: false, //可用门店表格的加载
      tableDataDetail: [], //价格方案表格数据
      tableDataAvailable: [], //可用门店表格数据
      vivwH: 0 //页面高度
    }
  },

  computed: {
    tableHeight() {
      return this.vivwH - 115
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  created() {
    this.getList()
  },
  methods: {
    limitInputlength,
    unitPriceChange(val, row) {
      let obj = row.units.find((v) => v.unitId == val);
      this.$set(row, "purPrice", obj.purPrice);
      this.$set(row, "salePrice", obj.salePrice);
      if (this.radioItem.goodsType == 7) {
        this.goodsQtyChange(row);
      }
    },
    goodsQtyChange(row) {
      this.$set(row, 'purMoney', fcount([row.goodsQty, row.purPrice], '*'))
      this.$set(row, 'saleMoney', fcount([row.goodsQty, row.salePrice], '*'))
    },
    //表格行hover时,显示操作加减号
    cellMouseEnter(row) {
      if (this.radioItem && this.radioItem.specialFlag != '*') {
        let eleIndex = this.tableDataAvailable.indexOf(row)
        for (let index = 0; index < this.tableDataAvailable.length; index++) {
          const element = this.tableDataAvailable[index]
          if (eleIndex == index) {
            element['hoverRow'] = true
          } else {
            element['hoverRow'] = false
          }
        }
        this.tableCellLabel = '操作'
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave() {
      // 移除hover的事件
      for (let index = 0; index < this.tableDataAvailable.length; index++) {
        const element = this.tableDataAvailable[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //保存按钮
    async submitForm() {
      this.loading = true
      let formAvailable = {
        goodsId: this.goodsId,
        unitId: 0,
        details: this.tableDataAvailable
      }
      try {
        await saveGoodsComboDetail(formAvailable)
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      } catch (error) {
      }
      this.loading = false
    },
    //点击方案信息当前行
    async handleRowClick(row) {
      // 切换行选中状态
      this.radioItem = row
      this.loadingAvailable = true
      this.selectGoodsName = row.goodsName
      this.goodsId = row.goodsId
      const res = await getGoodsCombo({ goodsId: row.goodsId, unitId: 0 })
      if (res.data?.details && res.data?.details?.length > 0) {
        this.tableDataAvailable = res.data?.details || []
      } else {
        this.tableDataAvailable = [{}]
      }
      this.loadingAvailable = false
    },
    //方案信息信息列表
    async getList() {
      this.loadingDetail = true
      const res = await listGoods(this.queryParams)
      this.tableDataDetail = res.rows
      this.total = res.total
      if (this.tableDataDetail?.length > 0) {
        this.handleRowClick(this.tableDataDetail[0])
      }
      this.loadingDetail = false
    },
    //选中的商品
    getGoodS(value) {
      this.loadingAvailable = true
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      const findIndex = value?.[0]?.findIndex;
      if (value.length == 1 && findIndex != undefined) {
        this.tableDataAvailable[findIndex] = value[0];
      } else {
        this.tableDataAvailable.push(...value);
      }
      this.tableDataAvailable = this.tableDataAvailable.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
      this.loadingAvailable = false
    },
    //适用门店表格当中增加/减少一行方法
    rowAvailable(name, index) {
      if (name === 'push') {
        this.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        if (this.tableDataAvailable.length <= 1) {
          this.tableDataAvailable = [{}]
          return
        }
        this.tableDataAvailable.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
//输入框原生属性
/**
   * 解决el-input设置类型为number时，中文输入法光标上移问题
   **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
   * 解决el-input设置类型为number时，去掉输入框后面上下箭头
   **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::v-deep .el-input__suffix-inner {
  z-index: 11;
  height: 100%;
  display: inline-block;
  display: flex;
  justify-content: center;
  align-items: center;
}

// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //关系情况
  .content {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //左边
    .cardDetail {
      height: 100%;
      margin-bottom: 0;
      width: 45%;
      margin-right: 10px;
    }
    //商品表格卡片
    .cardAvailable {
      height: 100%;
      margin-bottom: 0;
      width: 55%;
      margin-bottom: 6px;
      // 标题
      .cardTitle {
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #dedede;
        .titleTop {
          color: #333333;
          font-weight: 700;
          font-size: 14px;
          padding-left: 6px;
          border-left: 3px solid #1596fd;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    //表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
