<template>
  <div class="wrap" id="wrap">
    <cardTitleCom cardTitle="单据信息" class="cardTitle">
      <template slot="cardContent">
        <div class="tableContent">
          <!-- 操作按钮 -->
          <operatingButton :getList="getList" :isRecycleBtn="true" :isDeleteBtn="false" :multiple="multiple"
            @handleAdd="handleAdd" @handleDelete="handleDelete" @handleRedoDelete="dialogOptions.show = true" />
          <el-table v-loading="loadingTable" ref="multipleTable" @row-click="handleRowClick" tooltip-effect="dark"
            :data="tableData" @selection-change="handleSelectionChange" border :max-height="tableHeight"
            :height="tableHeight">
            <el-table-column type="selection" width="50" :selectable="disableCheckbox" align="center"></el-table-column>
            <el-table-column label="序号" align="center" prop="userId" width="80" type="index" />
            <el-table-column show-overflow-tooltip label="方案编号" align="center" prop="priceCaseNo">
              <template slot-scope="scope">
                <el-link :disabled="scope.row.specialFlag == '*'" type="primary" @click="handleDetail(scope.row)"
                  target="_blank">
                  {{ scope.row.priceCaseNo }}
                </el-link>
              </template>
            </el-table-column>
            <el-table-column label="方案名称" align="center" prop="priceCaseName" />

            <el-table-column show-overflow-tooltip label="价格因子" align="center" prop="priceTypeName" />
            <el-table-column label="计算方式" align="center" prop="priceCalcType">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.goods_price_calc_type" :value="scope.row.priceCalcType" />
              </template>
            </el-table-column>

            <el-table-column label="计算值" align="center" prop="priceValue" />
            <el-table-column label="舍入方式" align="center" prop="roundMode">
              <template slot-scope="scope">
                <dict-tag :options="dict.type.goods_round_mode" :value="scope.row.roundMode" />
              </template>
            </el-table-column>
          </el-table>
          <bottomPage v-model="queryParams" :getList="getList" :idsCom="ids" :totalCom="total"
            @selectAllCom="selectAll" />
        </div>
      </template>
    </cardTitleCom>
    <c-dialog :title="title" :width="730" :showDialog.sync="dialogVisible">
      <template #content>
        <el-form ref="form" :model="priceCaseForm" label-width="90px" :rules="rules" v-loading="loadingDialog">
          <div class="x-x">
            <el-form-item label="方案编号" prop="priceCaseNo">
              <el-input v-model="priceCaseForm.priceCaseNo" autocomplete="off" class="inputWidthM" placeholder="请输入方案编号"
                maxlength="4"></el-input>
            </el-form-item>
            <el-form-item label="方案名称" prop="priceCaseName">
              <el-input v-model="priceCaseForm.priceCaseName" autocomplete="off" class="inputWidthM"
                placeholder="请输入方案名称"></el-input>
            </el-form-item>
          </div>
          <div class="x-x">
            <el-form-item label="价格因子" prop="priceType">
              <el-select v-model="priceCaseForm.priceType" placeholder="请选择价格因子" class="inputWidthM" clearable
                filterable>
                <el-option label="标准售价" :value="1"></el-option>
                <el-option label="标准进价" :value="2"></el-option>
                <el-option label="最新进价" :value="3"></el-option>
                <el-option label="最新成本价" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="舍入方式" prop="roundMode">
              <el-select class="inputWidthM" v-model="priceCaseForm.roundMode" placeholder="请选择舍入方式" clearable
                filterable>
                <el-option v-for="dict in dict.type.goods_round_mode" :key="dict.value" :label="dict.label"
                  :value="dict.value" />
              </el-select>
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="计算方式" prop="priceCalcType">
              <el-select v-model="priceCaseForm.priceCalcType" placeholder="请选择计算方式" class="inputWidthM" clearable
                filterable>
                <el-option v-for="dict in dict.type.goods_price_calc_type" :key="dict.value" :label="dict.label"
                  :value="dict.value" />
              </el-select>
            </el-form-item>
            <el-form-item label="计算值" prop="priceValue">
              <el-input type="number" v-model="priceCaseForm.priceValue" autocomplete="off" class="inputWidthM"
                placeholder="请输入计算值"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </template>
      <template #buttonList>
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="getList" />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import bottomPage from '@/views/components/bottomPage' //底部分页
import {
  priceCaseListAPI,
  priceCaseAddAPI,
  priceCaseDetailAPI,
  priceCaseUpdateAPI,
  priceCaseDeleteAPI,
  getGoodsPriceCaseAutoNo,
  restorePriceCase
} from '@/api/system/goodsPrice/priceCase' //商品价格方案
import { payChannelListAPI } from '@/api/shop/base/payChannel' //支付通道
import operatingButton from '@/views/components/operatingButton' //操作按钮
import CDialog from '@/components/dialogTemplate/dialog/index.vue'
import Dialog2 from '@/components/Dialog' //回收站
export default {
  name: 'priceCase',
  dicts: ['goods_round_mode', 'goods_price_calc_type'],
  components: { bottomPage, cardTitleCom, operatingButton, CDialog, Dialog2 },
  data() {
    return {
      dialogOptions: {
        type: 'ShopInfo',
        show: false,
        title: '回收站',
        width: '75vw',
        data: {
          getListApi: priceCaseListAPI,
          restoreListApi: restorePriceCase,
          id: 'priceCaseId',
          columns: [
            {
              prop: 'priceCaseNo',
              label: '方案编号',
              minWidth: 180
            },
            {
              prop: 'priceCaseName',
              label: '方案名称',
              minWidth: 180
            },
            {
              prop: 'priceTypeName',
              label: '价格因子',
              minWidth: 100
            },
            {
              prop: 'priceCalcTypeName',
              label: '计算方式',
              minWidth: 100
            },
            {
              prop: 'priceValue',
              label: '计算值',
              minWidth: 100
            },
            {
              prop: 'roundModeName',
              label: '舍入方式',
              minWidth: 120,
              type: 'dict',
              dict: 'goods_round_mode'
            }
          ]
        }
      },
      dialogVisible: false, //弹框
      title: '价格方案', //价格方案
      //表格遮罩
      loadingTable: false,
      //弹窗
      loadingDialog: false,
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      ids: [], // 选中数组
      total: 0, // 总条数
      tableData: [], //表格数据
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      priceCaseForm: {
        priceType: 1,
        roundMode: '1',
        priceCalcType: '*',
        priceValue: 1
      }, //表单数据
      rules: {
        priceCaseNo: [
          {
            required: true,
            message: '方案编号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        priceCaseName: [
          {
            required: true,
            message: '方案名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        priceType: [
          {
            required: true,
            message: '价格因子不能为空',
            trigger: ['blur', 'change']
          }
        ],
        roundMode: [
          {
            required: true,
            message: '舍入方式不能为空',
            trigger: ['blur', 'change']
          }
        ],
        priceCalcType: [
          {
            required: true,
            message: '计算方式不能为空',
            trigger: ['blur', 'change']
          }
        ],
        priceValue: [
          {
            required: true,
            message: '请输入计算值',
            trigger: ['blur', 'change']
          },
          {
            validator: (rule, value, callback) => {
              if (value < 0) {
                callback(new Error('数量不能小于0'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }, //校验
      vivwH: 0, //页面高度
      paginationH: 40
    }
  },

  created() {
    this.getList()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - 130
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    disableCheckbox(row) {
      return row.specialFlag != '*'
    },
    //删除
    async handleDelete() {
      await priceCaseDeleteAPI(this.ids)
      await this.getList()
      this.$message({
        type: 'success',
        message: '删除商品价格方案成功'
      })
    },
    // 详情
    async handleDetail(row) {
      this.loadingDialog = true
      this.reset()
      this.title = '编辑价格方案'
      this.dialogVisible = true
      const res = await priceCaseDetailAPI(row.priceCaseId)
      this.priceCaseForm = res.data
      this.loadingDialog = false
    },
    //确定
    submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          try {
            if (this.priceCaseForm.priceCaseId) {
              await priceCaseUpdateAPI(this.priceCaseForm)
              this.$message({
                type: 'success',
                message: '修改商品价格方案成功'
              })
            } else {
              await priceCaseAddAPI(this.priceCaseForm)
              this.$message({
                type: 'success',
                message: '新增商品价格方案成功'
              })
            }
            await this.getList()
            this.dialogVisible = false
          } catch (error) {
            this.$message.error('操作失败！')
          }
        }
      })
    },
    //取消
    cancel() {
      this.reset()
      this.dialogVisible = false
    },
    //刷新列表接口
    async getList() {
      //打开遮罩
      this.loadingTable = true
      const res = await priceCaseListAPI(this.queryParams)
      this.tableData = res.rows
      this.total = res.total
      //关闭遮罩
      this.loadingTable = false
    },
    // 表单重置
    reset() {
      this.priceCaseForm = {
        priceType: 1,
        roundMode: '1',
        priceCalcType: '*',
        priceValue: 1
      }
      this.resetForm('form')
    },
    //新增
    async handleAdd() {
      this.reset()
      this.title = '新增价格方案'
      this.dialogVisible = true
      this.loadingDialog = true
      // 记录操
      try {
        const res = await getGoodsPriceCaseAutoNo()
        this.$set(this.priceCaseForm, 'priceCaseNo', res.data)
        this.loadingDialog = false
      } catch (error) {
        this.loadingDialog = false
      }
    },

    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      if (row.specialFlag != '*') {
        this.$refs.multipleTable.toggleRowSelection(row)
      }
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.priceCaseId) //单据id
      this.multiple = !selection.length
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true
        const res = await priceCaseListAPI({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total
        })
        //重新渲染表格
        this.tableData = res.rows
        //表格多选
        this.tableData.map(row => {
          this.$nextTick(() => {
            if (row.specialFlag != '*') {
              this.$refs.multipleTable.toggleRowSelection(row, value)
            }
          })
        })
        this.loadingTable = false
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection()
        })
        this.getList()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  .cardTitle {
    height: calc(100vh - 84px);
    margin-bottom: 0;
    //源单信息表格
    .tableContent {
      padding: 6px 10px;
    }
  }

  ::v-deep .el-dialog__body {
    padding-bottom: 0;
  }
  //输入框原生属性
  /**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
  ::v-deep .el-input__inner {
    line-height: 1px !important;
  }

  /**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
  ::v-deep input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
