var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "wrap", attrs: { id: "wrap" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "content",
      },
      [
        _c(
          "cardTitleCom",
          { staticClass: "cardDetail", attrs: { cardTitle: "商品捆绑/混售" } },
          [
            _c("template", { slot: "cardContent" }, [
              _c(
                "div",
                { staticClass: "tableContent" },
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loadingDetail,
                          expression: "loadingDetail",
                        },
                      ],
                      attrs: {
                        "tooltip-effect": "dark",
                        data: _vm.tableDataDetail,
                        border: "",
                        "max-height": _vm.tableHeight,
                        height: _vm.tableHeight,
                      },
                      on: { "row-click": _vm.handleRowClick },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { width: "50", align: "center" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-radio",
                                  {
                                    attrs: { label: scope.row },
                                    model: {
                                      value: _vm.radioItem,
                                      callback: function ($$v) {
                                        _vm.radioItem = $$v
                                      },
                                      expression: "radioItem",
                                    },
                                  },
                                  [_vm._v(_vm._s(""))]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "序号",
                          align: "center",
                          prop: "userId",
                          width: "60",
                          type: "index",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品类型",
                          align: "center",
                          prop: "goodsTypeName",
                          "min-width": "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品编码",
                          align: "center",
                          prop: "goodsNo",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "商品名称",
                          align: "center",
                          prop: "goodsName",
                          "min-width": "120",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "规格",
                          align: "center",
                          prop: "goodsSpec",
                          "min-width": "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "单位",
                          align: "center",
                          prop: "unitName",
                          "min-width": "60",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "进价",
                          align: "center",
                          prop: "purPrice",
                          "min-width": "80",
                        },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          label: "售价",
                          align: "center",
                          prop: "salePrice",
                          "min-width": "80",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("bottomPage", {
                    attrs: {
                      getList: _vm.getList,
                      isSelectAll: false,
                      leftTotalCom: false,
                      totalCom: _vm.total,
                    },
                    model: {
                      value: _vm.queryParams,
                      callback: function ($$v) {
                        _vm.queryParams = $$v
                      },
                      expression: "queryParams",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
        _c("el-card", { staticClass: "cardAvailable" }, [
          _c(
            "div",
            { staticClass: "cardTitle x-bc" },
            [
              _c("span", { staticClass: "titleTop marR10" }, [
                _vm._v(_vm._s(_vm.selectGoodsName)),
              ]),
              _c(
                "el-button",
                {
                  staticClass: "marR10",
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v("保存 ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "tableContent" },
            [
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingAvailable,
                      expression: "loadingAvailable",
                    },
                  ],
                  attrs: {
                    "tooltip-effect": "dark",
                    data: _vm.tableDataAvailable,
                    border: "",
                    "max-height": _vm.tableHeight,
                    height: _vm.tableHeight,
                  },
                  on: {
                    "cell-mouse-enter": _vm.cellMouseEnter,
                    "cell-mouse-leave": _vm.cellMouseLeave,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: _vm.tableCellLabel,
                      width: "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.hoverRow
                              ? _c("i", {
                                  staticClass:
                                    "el-icon-circle-plus operatePush",
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowAvailable(
                                        "push",
                                        scope.$index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            scope.row.hoverRow
                              ? _c("i", {
                                  staticClass: "el-icon-remove operateDel",
                                  on: {
                                    click: function ($event) {
                                      return _vm.rowAvailable(
                                        "del",
                                        scope.$index
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                            !scope.row.hoverRow
                              ? _c("div", [_vm._v(_vm._s(scope.$index + 1))])
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "商品编码",
                        "min-width": "150",
                        align: "center",
                        prop: "goodsNo",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("SelectRemote", {
                                ref: "goodsNo" + "_" + scope.$index + "_1",
                                attrs: {
                                  size: "mini",
                                  option: _vm.optionGoods,
                                  filterable: true,
                                  placeholder: "选择商品",
                                },
                                model: {
                                  value: scope.row.goodsNo,
                                  callback: function ($$v) {
                                    _vm.$set(scope.row, "goodsNo", $$v)
                                  },
                                  expression: "scope.row.goodsNo",
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("* "),
                        ]),
                        _c("span", [_vm._v("商品编码")]),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: "商品名称",
                      align: "center",
                      prop: "goodsName",
                      "min-width": "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "规格",
                      align: "center",
                      prop: "goodsSpec",
                      "min-width": "60",
                    },
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: {
                        label: "单位",
                        align: "center",
                        prop: "unitId",
                        "min-width": "100",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c(
                                "el-select",
                                {
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function ($event) {
                                      return _vm.unitPriceChange(
                                        $event,
                                        scope.row
                                      )
                                    },
                                  },
                                  model: {
                                    value: scope.row.unitId,
                                    callback: function ($$v) {
                                      _vm.$set(scope.row, "unitId", $$v)
                                    },
                                    expression: "scope.row.unitId",
                                  },
                                },
                                _vm._l(scope.row.units, function (item) {
                                  return _c("el-option", {
                                    key: item.unitId,
                                    attrs: {
                                      label: item.unitName,
                                      value: item.unitId,
                                    },
                                  })
                                }),
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c("i", { staticStyle: { color: "#ff4949" } }, [
                          _vm._v("* "),
                        ]),
                        _c("span", [_vm._v("单位")]),
                      ]),
                    ],
                    2
                  ),
                  _c("el-table-column", {
                    attrs: {
                      label: "进价",
                      align: "center",
                      prop: "purPrice",
                      "min-width": "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "售价",
                      align: "center",
                      prop: "salePrice",
                      "min-width": "80",
                    },
                  }),
                  _vm.radioItem && _vm.radioItem.goodsType == 7
                    ? _c(
                        "el-table-column",
                        {
                          key: "goodsQty",
                          attrs: {
                            label: "成分数量",
                            align: "center",
                            prop: "goodsQty",
                            "min-width": "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: { size: "mini", maxlength: "8" },
                                      on: {
                                        input: function ($event) {
                                          return _vm.limitInputlength(
                                            scope.row.goodsQty,
                                            scope.row,
                                            "goodsQty",
                                            true,
                                            4
                                          )
                                        },
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          return _vm.goodsQtyChange(scope.row)
                                        },
                                      },
                                      model: {
                                        value: scope.row.goodsQty,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "goodsQty", $$v)
                                        },
                                        expression: "scope.row.goodsQty",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3268717597
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("i", { staticStyle: { color: "#ff4949" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", [_vm._v("成分数量")]),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm.radioItem && _vm.radioItem.goodsType == 6
                    ? _c(
                        "el-table-column",
                        {
                          key: "goodsRate",
                          attrs: {
                            label: "生成率(%)",
                            align: "center",
                            prop: "goodsRate",
                            "min-width": "100",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("el-input", {
                                      attrs: {
                                        type: "number",
                                        size: "mini",
                                        maxlength: "8",
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.limitInputlength(
                                            scope.row.goodsRate,
                                            scope.row,
                                            "goodsRate",
                                            true,
                                            4
                                          )
                                        },
                                      },
                                      model: {
                                        value: scope.row.goodsRate,
                                        callback: function ($$v) {
                                          _vm.$set(scope.row, "goodsRate", $$v)
                                        },
                                        expression: "scope.row.goodsRate",
                                      },
                                    }),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3677421588
                          ),
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c("i", { staticStyle: { color: "#ff4949" } }, [
                              _vm._v("* "),
                            ]),
                            _c("span", [_vm._v("生成率(%)")]),
                          ]),
                        ],
                        2
                      )
                    : _vm._e(),
                  _c("el-table-column", {
                    attrs: {
                      label: "进价金额",
                      align: "center",
                      prop: "purMoney",
                      "min-width": "80",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "售价金额",
                      align: "center",
                      prop: "saleMoney",
                      "min-width": "80",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }