<template>
  <div class="wrap" id="wrap" v-loading="loadingTable">
    <el-form ref="form" :model="priceListForm" :rules="rules">
      <cardTitleCom cardTitle="查询" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch v-model="queryParams" :getList="getList" @isShowHighCom="getIsShowHigh"
              :dropDownList="dropDownList" :isBillDate="false" :isSearchInput="true"
              isSearchInputTitle="输入商品编码、名称、条码查询" />
            <div v-show="showHigh" class="searchCriteria">
              <div class="marT10">
                <span class="fS14MR10">商品类别</span>
                <SelectRemote :multiple="true" class="marR15" v-model="queryParams.categoryIds" style="width: 250px"
                  :option="$select({ key: 'listCategory' }).option" />
                <!-- buttons: [
                      { type: 'add', label: 'categoryNo' },
                      { type: 'more', label: 'categoryName' }
                    ], -->
                <!-- <span class="fS14MR10">价格方案</span>
                <SelectRemote
                  v-model="queryParams.priceCaseId"
                  style="width: 220px"
                  :option="{
                    data: priceCaseList,
                    labels: [
                      { title: '编号', label: 'priceCaseNo' },
                      { title: '名称', label: 'priceCaseName' }
                    ],

                    value: 'priceCaseId',
                    label: 'priceCaseName'
                  }"
                /> -->
                <!-- buttons: [
                      { type: 'add', label: 'priceCaseNo' },
                      { type: 'more', label: 'priceCaseName' }
                    ], -->
              </div>
            </div>
          </div>
        </template>
      </cardTitleCom>
      <cardTitleCom cardTitle="商品信息" class="priceList">
        <template slot="cardContent">
          <div class="tableContent">
            <!-- 操作按钮 -->
            <operatingButton :getList="getList" :isAddBtn="false" :isDeleteBtn="false" @handRefresh="getList">
              <template #specialDiyBtn>
                <el-button class="iconfont icon-qingchu" size="mini" type="primary" @click="getClearPrice">
                  清除手工价格
                </el-button>
                <el-button type="primary" size="mini" @click="submitForm">保存
                </el-button>
              </template>
            </operatingButton>
            <el-table style="width: 100%" ref="multipleTable" tooltip-effect="dark" :data="priceListForm.tableData"
              border :max-height="tableHeight" :height="tableHeight">
              <el-table-column label="序号" align="center" width="80" type="index" fixed />
              <el-table-column show-overflow-tooltip label="商品编码" align="center" prop="goodsNo" min-width="120" fixed />
              <el-table-column label="商品名称" align="center" prop="goodsName" min-width="120" fixed />
              <el-table-column label="条码" align="center" prop="barcode" min-width="120" fixed />
              <el-table-column label="类别编码" align="center" prop="categoryNo" min-width="100" />
              <el-table-column label="类别名称" align="center" prop="categoryName" min-width="100" />
              <el-table-column label="规格" align="center" prop="goodsSpec" min-width="60" />
              <el-table-column label="单位" align="center" prop="unitName" min-width="60" />
              <el-table-column label="最新成本价" align="center" prop="lastCost" min-width="88" />
              <el-table-column label="最新进价" align="center" prop="lastPurNotaxPrice" min-width="75" />
              <el-table-column label="商品进价" align="center" prop="purPrice" min-width="75" />
              <el-table-column label="商品售价" align="center" prop="salePrice" min-width="75" />
              <el-table-column align="center" width="1" />
              <div v-if="priceCaseList.length > 0">
                <div v-for="(item, index) in priceCaseList" :key="item.priceCaseId">
                  <el-table-column align="center" prop="priceCaseName" :label="item.priceCaseName" min-width="140">
                    <el-table-column align="center" prop="name" :label="item.priceTypeName" min-width="140">
                      <template slot="header">
                        <span>{{ item.priceTypeName }}</span>
                        <span> (</span>
                        <span> {{ item.priceCalcType }}</span>
                        <span> {{ item.priceValue }}</span>
                        <span>)</span>
                      </template>
                      <template slot-scope="scope">
                        <div class="red-text" v-if="
                            scope.row.basGoodsPriceCaseHandVos[index]
                              .handPrice != undefined
                          ">
                          <el-form-item :prop="
                              'tableData.' +
                              scope.$index +
                              '.basGoodsPriceCaseHandVos.' +
                              index +
                              '.handPrice'
                            " :rules="rules[`tableData.handPrice`]">
                            <el-input size="mini" :disabled="item.specialFlag == '*'" type="number" v-model="
                                scope.row.basGoodsPriceCaseHandVos[index]
                                  .handPrice
                              " @change="
                                handPriceChange(
                                  scope.row.goodsId,
                                  index,
                                  scope.row.basGoodsPriceCaseHandVos[index]
                                    .handPrice,
                                  scope.row.basGoodsPriceCaseHandVos[index]
                                )
                              " />
                          </el-form-item>
                        </div>
                        <div v-else>
                          <el-form-item :prop="
                              'tableData.' +
                              scope.$index +
                              '.basGoodsPriceCaseHandVos.' +
                              index +
                              '.standardPrice'
                            " :rules="rules[`tableData.standardPrice`]">
                            <el-input size="mini" :disabled="item.specialFlag == '*'" type="number" v-model="
                                scope.row.basGoodsPriceCaseHandVos[index]
                                  .standardPrice
                              " @change="
                                standardPriceChange(
                                  scope.row.goodsId,
                                  index,
                                  scope.row.basGoodsPriceCaseHandVos[index]
                                    .standardPrice,
                                  scope.row.basGoodsPriceCaseHandVos[index]
                                )
                              " />
                          </el-form-item>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table-column>
                </div>
              </div>
            </el-table>
            <bottomPage v-model="queryParams" :getList="getList" :totalCom="total" :isSelectAll="false"
              class="marB10" />
          </div>
        </template>
      </cardTitleCom>
    </el-form>
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import seniorSearch from '@/views/components/seniorSearch' //高级搜索
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import {
  priceListListAPI,
  priceListSaveOrUpdateAPI,
  clearPriceCaseHandAPI
} from '@/api/system/goodsPrice/priceList' //商品价格方案手工价格
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'
import operatingButton from '@/views/components/operatingButton' //操作按钮
import bottomPage from '@/views/components/bottomPage' //底部分页
import { fcount } from '@/utils'
export default {
  name: 'priceList',
  components: {
    cardTitleCom,
    seniorSearch,
    SelectRemote,
    operatingButton,
    bottomPage
  },
  data() {
    return {
      icon: 'el-icon-caret-bottom', //高级搜索图标
      //搜索框搜索的条件
      dropDownList: [
        { parameter: 'query', name: '全部' },
        { parameter: 'goodsNos', name: '商品编码' },
        { parameter: 'goodsNames', name: '商品名称' },
        { parameter: 'barcodes', name: '商品条码' }
      ],
      FIXED: '不舍入', //舍入方式

      loading: false,
      loadingTable: false,
      multiple: true, // 非单个禁用
      showHigh: false, //是否显示高级搜索
      ids: [], // 选中数组
      total: 0, // 总条数
      priceCaseList: [], //商品价格方案
      oldTableData: [], //缓存
      //查询
      queryParams: {
        pageNum: 1,
        pageSize: 15
      },
      //校验表单
      priceListForm: {
        tableData: []
      },
      //校验规则
      rules: {
        'tableData.handPrice': [
          {
            required: true,
            message: '价格不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'tableData.standardPrice': [
          {
            required: true,
            message: '价格不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40
    }
  },
  async created() {
    //初始化全部数据
    await this.getInitializeData()
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 135
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.multipleTable.doLayout()
    })
  },
  methods: {
    handleResize() {
      this.vivwH = document.getElementById('wrap').clientHeight
      this.searchH = document.getElementById('search-card').clientHeight
    },
    //清空手工价格
    async getClearPrice() {
      await clearPriceCaseHandAPI()
      this.$message({
        message: '清空手工价格成功',
        type: 'success'
      })
      await this.getList()
    },
    //保存
    async submitForm() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          await priceListSaveOrUpdateAPI(this.priceListForm.tableData)
          //渲染列表
          await this.getList()
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        }
      })
    },
    //手工价改变事件
    handPriceChange(goodsId, index, handPriceNew, row) {
      this.oldTableData.forEach(item => {
        if (item.goodsId == goodsId) {
          if (!handPriceNew) {
            // this.$set(row, 'standardPrice', handPriceNew)
            this.$set(
              row,
              'handPrice',
              item.basGoodsPriceCaseHandVos[index].handPrice
            )
          } else {
            // this.$set(row, 'handPrice', undefined)
          }
        }
      })
    },

    //标准价改变事件
    standardPriceChange(goodsId, index, standardPriceNew, row) {
      this.oldTableData.forEach(item => {
        if (item.goodsId == goodsId) {
          if (
            item.basGoodsPriceCaseHandVos[index].standardPrice !=
            standardPriceNew
          ) {
            this.$set(row, 'handPrice', standardPriceNew)
            this.$set(
              row,
              'standardPrice',
              item.basGoodsPriceCaseHandVos[index].standardPrice
            )
          } else {
            // this.$set(row, 'handPrice', undefined)
          }
        }
      })
    },
    //初始化
    async getInitializeData() {
      this.loading = true
      //商品价格方案列表
      const res2 = await priceCaseListAPI({ pageNum: 1, pageSize: 1000000 })
      this.priceCaseList = res2.rows
      //渲染列表
      await this.getList()
      this.loading = false
    },
    //列表
    async getList() {
      //遮罩
      this.loadingTable = true
      //手工价格列表
      let res3 = await priceListListAPI(this.queryParams)
      this.priceListForm.tableData = res3.rows
      this.total = res3.total

      this.priceListForm.tableData.forEach(item => {
        if (
          item.basGoodsPriceCaseHandVos &&
          item.basGoodsPriceCaseHandVos.length > 0
        ) {
          //遍历手工数组
          item.basGoodsPriceCaseHandVos.forEach((eachItem, index) => {
            //计算
            //标准价格
            let standardPrice = 0
            let priceCalcType = this.priceCaseList[index]?.priceCalcType // 计算符号变量
            let priceValue = Number(this.priceCaseList[index]?.priceValue) // 计算值变量
            const decimalPlaces = (priceValue.toString().split('.')[1] || '')
              .length // 计算值变量小数位数
            let ToFixed = this.getToFixed(this.priceCaseList[index]?.roundMode) //舍入几位
            let numTol = undefined //一共几位小数
            //价格因子
            if (this.priceCaseList[index]?.priceType == 1) {
              //售价
              let salePrice = Number(item.salePrice) // 售价变量
              const decimalPlaces2 = (salePrice.toString().split('.')[1] || '')
                .length // 售价变量小数位数
              numTol = decimalPlaces + decimalPlaces2
              // 根据计算符号进行计算
              switch (priceCalcType) {
                case '+':
                  if (ToFixed == '不舍入') {
                    standardPrice = salePrice + priceValue // 加法
                  } else {
                    // 加法
                    standardPrice = fcount([salePrice, priceValue], '+')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '-':
                  if (ToFixed == '不舍入') {
                    standardPrice = salePrice - priceValue // 减法
                  } else {
                    // 减法
                    standardPrice = fcount([salePrice, priceValue], '-')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '*':
                  if (ToFixed == '不舍入') {
                    standardPrice =
                      (salePrice *
                        (decimalPlaces2 === 0
                          ? 1
                          : Math.pow(10, decimalPlaces2)) *
                        (priceValue *
                          (decimalPlaces === 0
                            ? 1
                            : Math.pow(10, decimalPlaces)))) /
                      (numTol === 0 ? 1 : Math.pow(10, numTol)) // 乘法
                  } else {
                    // 乘法
                    standardPrice = fcount([salePrice, priceValue], '*')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '/':
                  if (ToFixed == '不舍入') {
                    standardPrice = salePrice / priceValue // 除法
                  } else {
                    // 除法
                    standardPrice = fcount([salePrice, priceValue], '/')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                default:
                  console.error('未知的计算符号') // 报错
              }
            } else if (this.priceCaseList[index]?.priceType == 2) {
              //进价
              let purPrice = Number(item.purPrice) // 进价变量
              const decimalPlaces2 = (purPrice.toString().split('.')[1] || '')
                .length // 售价变量小数位数
              numTol = decimalPlaces + decimalPlaces2
              // 根据计算符号进行计算
              switch (priceCalcType) {
                case '+':
                  if (ToFixed == '不舍入') {
                    standardPrice = purPrice + priceValue // 加法
                  } else {
                    // 加法
                    standardPrice = fcount([purPrice, priceValue], '+')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '-':
                  if (ToFixed == '不舍入') {
                    standardPrice = purPrice - priceValue // 减法
                  } else {
                    // 减法
                    standardPrice = fcount([purPrice, priceValue], '-')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '*':
                  if (ToFixed == '不舍入') {
                    standardPrice =
                      (purPrice *
                        (decimalPlaces2 === 0
                          ? 1
                          : Math.pow(10, decimalPlaces2)) *
                        (priceValue *
                          (decimalPlaces === 0
                            ? 1
                            : Math.pow(10, decimalPlaces)))) /
                      (numTol === 0 ? 1 : Math.pow(10, numTol)) // 乘法
                  } else {
                    // 乘法
                    standardPrice = fcount([purPrice, priceValue], '*')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }

                  break
                case '/':
                  if (ToFixed == '不舍入') {
                    standardPrice = purPrice / priceValue // 除法
                  } else {
                    // 除法
                    standardPrice = fcount([purPrice, priceValue], '/')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                default:
                  console.error('未知的计算符号') // 报错
              }
            } else if (this.priceCaseList[index]?.priceType == 3) {
              //最新进价
              let lastPurNotaxPrice = Number(item.lastPurNotaxPrice) // 进价变量
              const decimalPlaces2 = (lastPurNotaxPrice.toString().split('.')[1] || '')
                .length // 售价变量小数位数
              numTol = decimalPlaces + decimalPlaces2
              // 根据计算符号进行计算
              switch (priceCalcType) {
                case '+':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastPurNotaxPrice + priceValue // 加法
                  } else {
                    // 加法
                    standardPrice = fcount([lastPurNotaxPrice, priceValue], '+')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '-':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastPurNotaxPrice - priceValue // 减法
                  } else {
                    // 减法
                    standardPrice = fcount([lastPurNotaxPrice, priceValue], '-')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '*':
                  if (ToFixed == '不舍入') {
                    standardPrice =
                      (lastPurNotaxPrice *
                        (decimalPlaces2 === 0
                          ? 1
                          : Math.pow(10, decimalPlaces2)) *
                        (priceValue *
                          (decimalPlaces === 0
                            ? 1
                            : Math.pow(10, decimalPlaces)))) /
                      (numTol === 0 ? 1 : Math.pow(10, numTol)) // 乘法
                  } else {
                    // 乘法
                    standardPrice = fcount([lastPurNotaxPrice, priceValue], '*')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }

                  break
                case '/':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastPurNotaxPrice / priceValue // 除法
                  } else {
                    // 除法
                    standardPrice = fcount([lastPurNotaxPrice, priceValue], '/')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                default:
                  console.error('未知的计算符号') // 报错
              }
            }else if (this.priceCaseList[index]?.priceType == 4) {
              //最新成本价
              let lastCost = Number(item.lastCost) // 进价变量
              const decimalPlaces2 = (lastCost.toString().split('.')[1] || '')
                .length // 售价变量小数位数
              numTol = decimalPlaces + decimalPlaces2
              // 根据计算符号进行计算
              switch (priceCalcType) {
                case '+':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastCost + priceValue // 加法
                  } else {
                    // 加法
                    standardPrice = fcount([lastCost, priceValue], '+')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '-':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastCost - priceValue // 减法
                  } else {
                    // 减法
                    standardPrice = fcount([lastCost, priceValue], '-')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                case '*':
                  if (ToFixed == '不舍入') {
                    standardPrice =
                      (lastCost *
                        (decimalPlaces2 === 0
                          ? 1
                          : Math.pow(10, decimalPlaces2)) *
                        (priceValue *
                          (decimalPlaces === 0
                            ? 1
                            : Math.pow(10, decimalPlaces)))) /
                      (numTol === 0 ? 1 : Math.pow(10, numTol)) // 乘法
                  } else {
                    // 乘法
                    standardPrice = fcount([lastCost, priceValue], '*')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }

                  break
                case '/':
                  if (ToFixed == '不舍入') {
                    standardPrice = lastCost / priceValue // 除法
                  } else {
                    // 除法
                    standardPrice = fcount([lastCost, priceValue], '/')
                    standardPrice = this.formatNumber(standardPrice, ToFixed)
                  }
                  break
                default:
                  console.error('未知的计算符号') // 报错
              }
            }
            //标准价
            this.$set(eachItem, 'standardPrice', standardPrice)
            //舍入方式
            this.$set(
              eachItem,
              'roundMode',
              this.priceCaseList[index]?.roundMode
            )
            //计算符号
            this.$set(
              eachItem,
              'priceCalcType',
              this.priceCaseList[index]?.priceCalcType
            )
            //计算值
            this.$set(
              eachItem,
              'priceValue',
              this.priceCaseList[index]?.priceValue
            )
          })
        }
      })
      console.log('重装后的', this.priceListForm.tableData)
      //缓存
      this.oldTableData = JSON.parse(
        JSON.stringify(this.priceListForm.tableData)
      )
      this.loadingTable = false
    },
    //舍入方式
    getToFixed(value) {
      this.FIXED = '不舍入'
      // 根据舍入方式进行计算
      switch (value) {
        case '0':
          this.FIXED = '不舍入' //不舍入
          break
        case '1':
          this.FIXED = 2 //舍入到分
          break
        case '2':
          this.FIXED = 1 //舍入到角
          break
        case '3':
          this.FIXED = 0 //舍入到元
          break
        default:
          console.error('未知的舍入方式') // 报错
      }
      return this.FIXED
    },
    //是否显示高级搜索
    getIsShowHigh(value) {
      this.showHigh = value
      this.$nextTick(() => {
        this.handleResize()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
//外面盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 搜索
  .navSearch {
    padding: 10px 10px 10px 20px;
  }
  .priceList {
    //源单信息表格
    .tableContent {
      padding: 6px 10px;
    }
  }
}
.icon-qingchu {
  font-size: 12px;
}
//手工价变红
.red-text {
  display: inline-block;
  ::v-deep .el-input--medium .el-input__inner {
    color: red !important;
  }
}
//表单校验
::v-deep .el-form-item__content {
  margin-left: 0 !important;
}
::v-deep .el-form-item__error {
  position: static;
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
//输入框原生属性
/**
 * 解决el-input设置类型为number时，中文输入法光标上移问题
 **/
::v-deep .el-input__inner {
  line-height: 1px !important;
}

/**
 * 解决el-input设置类型为number时，去掉输入框后面上下箭头
 **/
::v-deep input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
// ::v-deep .el-table__body-wrapper {
//   height: 100% !important;
// }
</style>
