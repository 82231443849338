<template>
  <div class="wrap" id="wrap">
    <!-- 关系情况 -->
    <div class="content" v-loading="loading">
      <cardTitleCom cardTitle="方案信息" class="cardDetail">
        <template slot="cardContent">
          <div class="tableContent">
            <el-table
              tooltip-effect="dark"
              :data="tableDataDetail"
              border
              :max-height="tableHeight"
              :height="tableHeight"
              v-loading="loadingDetail"
              @row-click="handleRowClick"
            >
              <el-table-column width="50" align="center">
                <template v-slot="scope">
                  <el-radio v-model="radioItem" :label="scope.row">{{
                    ''
                  }}</el-radio>
                </template>
              </el-table-column>
              <el-table-column
                label="序号"
                align="center"
                prop="userId"
                width="60"
                type="index"
              />
              <el-table-column
                label="方案编号"
                align="center"
                prop="priceCaseNo"
                width="180"
              >
              </el-table-column>
              <el-table-column
                label="方案名称"
                align="center"
                prop="priceCaseName"
              >
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
      <el-card class="cardAvailable">
        <div class="cardTitle x-bc">
          <span class="titleTop marR10">{{ selectPriceCaseName }}</span>
          <el-button
            class="marR10"
            type="primary"
            size="mini"
            @click="submitForm"
            >保存
          </el-button>
        </div>

        <div class="tableContent">
          <el-table
            tooltip-effect="dark"
            :data="tableDataAvailable"
            border
            :max-height="tableHeight"
            :height="tableHeight"
            v-loading="loadingAvailable"
            @cell-mouse-enter="cellMouseEnter"
            @cell-mouse-leave="cellMouseLeave"
          >
            <el-table-column align="center" :label="tableCellLabel" width="80">
              <template v-slot="scope">
                <i
                  v-if="scope.row.hoverRow"
                  @click="rowAvailable('push', scope.$index)"
                  class="el-icon-circle-plus operatePush"
                ></i>
                <i
                  v-if="scope.row.hoverRow"
                  @click="rowAvailable('del', scope.$index)"
                  class="el-icon-remove operateDel"
                ></i>
                <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
              </template>
            </el-table-column>

            <el-table-column label="供应商编号" align="center" prop="partnerNo">
              <template slot-scope="scope">
                <el-input
                  :disabled="radioItem && radioItem.specialFlag == '*'"
                  v-model="scope.row.partnerNo"
                  @keyup.enter.native="keyup('paramName', $event, scope.$index)"
                >
                  <i
                    slot="suffix"
                    class="el-icon-more more"
                    @click="selectShopInfo"
                  ></i>
                </el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="供应商名称"
              align="center"
              prop="partnerName"
            />
            <el-table-column
              label="供应商组别"
              align="center"
              prop="groupName"
            />
          </el-table>
        </div>
      </el-card>
    </div>
    <!-- 供应商资料 -->
    <selectSupplierMultiple
      :openSelectSupplierM.sync="openSelectSupplierM"
      :queryCode="queryCode"
      @selectionSupplierCom="selectionSupplierCom"
    />
  </div>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' //卡片标题
import { listSupplier } from '@/api/goods/supplier' //供应商资料
import selectSupplierMultiple from '@/views/components/selectSupplierMultiple' //供应商资料弹窗
import { priceCaseListAPI } from '@/api/system/goodsPrice/priceCase' //商品价格方案
import {
  keGongPriceAddAPI,
  keGongPriceDetailAPI
} from '@/api/system/goodsPrice/keGongPrice' //客户、供应商价格方案
export default {
  name: 'supplierPrice',
  components: { cardTitleCom, selectSupplierMultiple },
  data () {
    return {
      tableCellLabel: '序号',
      radioItem: undefined, //单选
      priceCaseId: undefined, //焦点方案id
      selectPriceCaseName: '供应商信息', //当前选中的方案信息的名称
      loading: false, //遮罩层
      loadingDetail: false, //价格方案表格的加载
      loadingAvailable: false, //可用供应商表格的加载
      tableDataDetail: [], //价格方案表格数据
      tableDataAvailable: [], //可用供应商表格数据
      queryCode: undefined, //传送供应商参数
      openSelectSupplierM: false, //选择供应商开关
      vivwH: 0 //页面高度
    }
  },
  created () {
    this.getList()
  },
  computed: {
    tableHeight () {
      return this.vivwH - 65
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.vivwH = document.getElementById('wrap').clientHeight
    })
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      if (this.radioItem && this.radioItem.specialFlag != '*') {
        let eleIndex = this.tableDataAvailable.indexOf(row)
        for (let index = 0; index < this.tableDataAvailable.length; index++) {
          const element = this.tableDataAvailable[index]
          if (eleIndex == index) {
            element['hoverRow'] = true
          } else {
            element['hoverRow'] = false
          }
        }
        this.tableCellLabel = '操作'
      }
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      // 移除hover的事件
      for (let index = 0; index < this.tableDataAvailable.length; index++) {
        const element = this.tableDataAvailable[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    handleResize () {
      this.vivwH = document.getElementById('wrap').clientHeight
    },
    //保存按钮
    async submitForm () {
      // const isEmpty = this.tableDataAvailable.filter(
      //   item =>
      //     (!(JSON.stringify(item) === '{}') &&
      //       !(item.partnerId === undefined)) ||
      //     !(item.partnerId === undefined)
      // )
      // if (isEmpty.length <= 0) {
      //   this.$message.error('可用供应商不能为空')
      //   return
      // }
      this.loading = true
      let partnerIds = []
      this.tableDataAvailable.forEach(item => {
        if (item.partnerId) {
          partnerIds.push(item.partnerId)
        }
      })
      let formAvailable = {
        priceCaseId: this.priceCaseId,
        partnerIds,
        partnerType: 2
      }
      await keGongPriceAddAPI(formAvailable)
      this.$message({
        message: '保存成功',
        type: 'success'
      })
      // await this.getList()
      // const res = await keGongPriceDetailAPI({
      //   partnerType: 2,
      //   priceCaseId: this.priceCaseId
      // })
      // if (res.data && res.data.length > 0) {
      //   this.tableDataAvailable = res.data
      // } else {
      //   this.tableDataAvailable = [{}]
      // }
      this.loading = false
    },

    //点击方案信息当前行
    async handleRowClick (row, column, event) {
      // 切换行选中状态
      this.radioItem = row
      this.loadingAvailable = true
      this.selectPriceCaseName = row.priceCaseName
      this.priceCaseId = row.priceCaseId
      const res = await keGongPriceDetailAPI({
        partnerType: 2,
        priceCaseId: this.priceCaseId
      })
      if (res.data && res.data.length > 0) {
        this.tableDataAvailable = res.data
      } else {
        this.tableDataAvailable = [{}]
      }
      this.loadingAvailable = false
      // this.$message({
      //   message: '查询成功',
      //   type: 'success'
      // })
    },
    //方案信息信息列表
    async getList () {
      this.loadingDetail = true
      const res = await priceCaseListAPI({ pageNum: 1, pageSize: 10000 })
      this.tableDataDetail = res.rows
      if (this.tableDataDetail?.length > 0) {
        this.handleRowClick(this.tableDataDetail[0])
      }
      this.loadingDetail = false
    },
    //选择商品方法
    selectShopInfo () {
      this.openSelectSupplierM = true
    },

    //表格单元格回车事件
    keyup (name, event, index) {
      this.loadingAvailable = true
      //判断是否输入编码或条码
      if (this.tableDataAvailable[`${index}`][`${name}`]) {
        listSupplier({
          [name]: this.tableDataAvailable[`${index}`][`${name}`]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            //去重
            let isRepeat = this.tableDataAvailable.every(
              item => item.partnerId != response.rows[0].partnerId
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此供应商!')
              this.loadingAvailable = false
              return
            }
            //使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.tableDataAvailable, index, response.rows[0])
          } else {
            this.queryCode = this.tableDataAvailable[`${index}`][`${name}`]
            this.openSelectSupplierM = true
          }
        })
      } else {
        //没输入直接弹出商品信息框
        event.target.blur()
        this.openSelectSupplierM = true
      }
      this.loadingAvailable = false
    },
    //选中的商品
    selectionSupplierCom (value) {
      this.loadingAvailable = true
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      //去重
      value = value.filter(item => {
        return this.tableDataAvailable.every(v => v.partnerId != item.partnerId)
      })
      if (value.length == 0) {
        this.loadingAvailable = false
        return
      }
      this.tableDataAvailable.push(...value)
      this.tableDataAvailable = this.tableDataAvailable.filter(
        item =>
          (!(JSON.stringify(item) === '{}') &&
            !(item.partnerId === undefined)) ||
          !(item.partnerId === undefined)
      )
      this.loadingAvailable = false
    },
    //适用供应商表格当中增加/减少一行方法
    rowAvailable (name, index) {
      if (name === 'push') {
        this.tableDataAvailable.splice(index + 1, 0, {})
      } else {
        if (this.tableDataAvailable.length <= 1) {
          this.tableDataAvailable = [{}]
          return
        }
        this.tableDataAvailable.splice(index, 1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 最大盒子
.wrap {
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  //关系情况
  .content {
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //左边
    .cardDetail {
      height: 100%;
      margin-bottom: 0;
      width: 35%;
      margin-right: 10px;
    }
    //商品表格卡片
    .cardAvailable {
      height: 100%;
      margin-bottom: 0;
      width: 65%;
      margin-bottom: 6px;
      // 标题
      .cardTitle {
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #dedede;
        .titleTop {
          color: #333333;
          font-weight: 700;
          font-size: 14px;
          padding-left: 6px;
          border-left: 3px solid #1596fd;
          height: 20px;
          line-height: 20px;
        }
      }
    }

    //表格
    .tableContent {
      margin: 6px 10px 10px 10px;
    }
  }
}
//卡片默认边距0
::v-deep .el-card__body {
  padding: 0;
  margin: 0;
}
</style>
